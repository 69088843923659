import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormater'
})
export class TimeFormaterPipe implements PipeTransform {


  transform(time: string): string {
    let hours = parseInt(time.slice(0, 2));
    let minutes = time.slice(3, 5);
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }


  transformCheckAm(time: string): boolean {
    let hours = parseInt(time.slice(0, 2));
    let minutes = time.slice(3, 5);
    let ampm = hours >= 12 ? 'PM' : 'AM';
    if(hours >= 12){
        return false
    }else{
        return true
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmService, DataService, MixpanelService } from '../services/index'
import { Error } from '../models/index'
import { Base64 } from 'js-base64';
import { PixelService } from '../pixel.service';
import moengage from "@moengage/web-sdk";

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  sub:any;
  confirmData:any;
  res:any;
  isError:boolean;
  errorMessage:any;
  successMessage:any;
  isWaiting:boolean;
  waitingMessage:any;
  api_error:Error;
  started=0;
  int1:any;
  int2:any;
  int3:any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ConfirmService,
    private dataService: DataService,
    private mixpanelService: MixpanelService,
    private pixel: PixelService,
  ) { }
  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      let data= params['id'];
      this.confirmData=JSON.parse(Base64.decode(data));

      let mixData = {
        route:this.confirmData.search_from + " - " + this.confirmData.search_to,
        busOperator: this.confirmData.operator.name,
        travelDate: this.confirmData.depature_date,
        travelTime: this.confirmData.departure_time,
        totalPassengers:this.confirmData.passengers.length,
        amount:0,
        bookingChannel:"Web",
        isReturnTrip:false,
        source: 'buupassSite',
        role: 'customer',
      }

console.log(this.confirmData)
      this.mixpanelService.track('ReserveSeat', mixData);


      this.confirmMpesa(this.confirmData);
    });
  }
  confirmMpesa(dataReceived:any){

    this.isWaiting=true;
    this.waitingMessage="Processing your payments please wait.....";
        let pay_id ='';
        let ticket_ref = ""
        pay_id = dataReceived.data.booking_id;
        ticket_ref =  dataReceived.data.ticket_references
        if(dataReceived.operator.alias =="moderncoast") {
          this.service.confirmModernCoast(ticket_ref).subscribe(
            response => {
              this.res=response;
              var self = this;
              if(this.res.data.status =='confirmed'){
                this.isWaiting=false;
                this.successMessage="Payment processed successfully";
                let f=Base64.encode(JSON.stringify(dataReceived))
                clearInterval(self.int1);
                clearInterval(self.int2);
                clearInterval(self.int3);
                this.dataService.changeSuccessMessage(f);

              const mixData = {
                name:self.confirmData.fullname,
                email:self.confirmData.email,
                residence:self.confirmData.from,
                route:self.confirmData.from + self.confirmData.to,
                travelDate: self.confirmData.departure_date,
                Busoperator: self.confirmData.operator,
                amount: self.confirmData.total_fare,
                ticketCount:self.confirmData.passengers.length,
                isReturnUser:false,
                isPromoCodeAdded:"",
                source: 'buupassSite',
                role: 'customer',
              }

              moengage.track_event('Payment Confirmed', {
                'BookingId':self.confirmData.data.booking_id,
                'PassengerName': self.confirmData.fullname,
                'PassengerEmail': self.confirmData.email,
                'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                'PassengerResidence': self.confirmData.search_from,
                'Busoperator': self.confirmData.operator.alias,
                'TotalFare':parseInt(self.confirmData.total_fare),
                'TotalPassengers':self.confirmData.passengers.length
              });

              
            this.pixel.trackCustom('CompletedPayment', {
              platform: 'web',
              name:self.confirmData.fullname,
              email:self.confirmData.email,
              residence:self.confirmData.from,
              route:self.confirmData.from + self.confirmData.to,
              travelDate: self.confirmData.departure_date,
              Busoperator: self.confirmData.operator,
              amount: self.confirmData.total_fare,
              ticketCount:self.confirmData.passengers.length,
            })

                this.mixpanelService.track('CompletePayment', mixData);
               

                this.router.navigate(['/booking-successful', 'thank-you'])

              }else{
                      this.isWaiting=true;
                      this.waitingMessage="Processing your payments please wait.....";
                        var self = this;
                                  setTimeout(function () {
                                    if(self.started==0){
                                       self.started=1
                                       var i = 1000;
                                       self.int2 = setInterval(function () {
                                        self.confirmMpesa(dataReceived);
                                        i += 1000;
                                        if (i >= 25000){
                                          clearInterval(self.int1);
                                          clearInterval(self.int2);
                                          clearInterval(self.int3);
                                          moengage.track_event('Payment Cancelled', {
                                            'BookingId':self.confirmData.data.booking_id,
                                            'PassengerName': self.confirmData.fullname,
                                            'PassengerEmail': self.confirmData.email,
                                            'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                                            'PassengerResidence': self.confirmData.search_from,
                                            'Busoperator': self.confirmData.operator.alias,
                                            'TotalFare':parseInt(self.confirmData.total_fare),
                                            'TotalPassengers':self.confirmData.passengers.length
                                          });
                                          moengage.destroy_session();
                                          window.location.href = '/index.html';
                                        } ;
                                      }, 10000);
                                    }
                                }, 1000);
              }
            },
            error => {
              this.isError=true;
              this.isWaiting=true;
              this.waitingMessage="Processing your payments please wait.....";
              this.api_error=new Error().deserialize(error.error.errors)
              var self = this;
                        setTimeout(function () {
                          if(self.started==0){
                             self.started=1
                             var i = 1000;
                              self.int3 = setInterval(function () {
                              self.confirmMpesa(dataReceived);
                              i += 1000;
                              if (i >= 25000){
                                  clearInterval(self.int1);
                                  clearInterval(self.int2);
                                  clearInterval(self.int3);
                                  moengage.track_event('Payment Cancelled', {
                                    'BookingId':self.confirmData.data.booking_id,
                                    'PassengerName': self.confirmData.fullname,
                                    'PassengerEmail': self.confirmData.email,
                                    'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                                    'PassengerResidence': self.confirmData.search_from,
                                    'Busoperator': self.confirmData.operator.alias,
                                    'TotalFare':parseInt(self.confirmData.total_fare),
                                    'TotalPassengers':self.confirmData.passengers.length
                                  });
                                  moengage.destroy_session();
                                  window.location.href = '/index.html';
                              } ;
                            }, 10000);
                          }
                      }, 1000);
            }
          );
        } else {
          this.service.confirmV2(pay_id).subscribe(
            response => {
              this.res=response;
              var self = this;
              if(this.res.status =='confirmed'){
                this.isWaiting=false;
                this.successMessage="Payment processed successfully";
                let f=Base64.encode(JSON.stringify(dataReceived))
                clearInterval(self.int1);
                clearInterval(self.int2);
                clearInterval(self.int3);
                this.dataService.changeSuccessMessage(f);
                this.router.navigate(['/booking-successful', 'thank-you'])
              }else if(this.res.status == 'cancelled' ){
                var self = this;
                this.isWaiting=false;
                this.isError=true;
                // this.errorMessage="Sorry your payment request was cancelled. Please follow the instructions sent via sms to confirm your booking";
                this.errorMessage="Sorry your payment request was cancelled.";

                if (this.res.reason == "validation"){
                    setTimeout(function () {
                      clearInterval(self.int1);
                      clearInterval(self.int2);
                      clearInterval(self.int3);
                      moengage.track_event('Payment Cancelled', {
                        'BookingId':self.confirmData.data.booking_id,
                        'PassengerName': self.confirmData.fullname,
                        'PassengerEmail': self.confirmData.email,
                        'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                        'PassengerResidence': self.confirmData.search_from,
                        'Busoperator': self.confirmData.operator.alias,
                        'TotalFare':parseInt(self.confirmData.total_fare),
                        'TotalPassengers':self.confirmData.passengers.length
                      });
                      moengage.destroy_session();
                      window.location.href = '/index.html';
                    }, 10000);
                }
                          setTimeout(function () {
                            if(self.started==0){
                               self.started=1
                               var i = 1000;
                                self.int1 = setInterval(function () {
                                self.confirmMpesa(dataReceived);
                                i += 1000;
                                if (i >= 25000){
                                  clearInterval(self.int1);
                                  clearInterval(self.int2);
                                  clearInterval(self.int3);
                                  moengage.track_event('Payment Cancelled', {
                                    'BookingId':self.confirmData.data.booking_id,
                                    'PassengerName': self.confirmData.fullname,
                                    'PassengerEmail': self.confirmData.email,
                                    'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                                    'PassengerResidence': self.confirmData.search_from,
                                    'Busoperator': self.confirmData.operator.alias,
                                    'TotalFare':parseInt(self.confirmData.total_fare),
                                    'TotalPassengers':self.confirmData.passengers.length
                                  });
                                  moengage.destroy_session();
                                  window.location.href = '/index.html';
                                } ;
                              }, 10000);
                            }
                        }, 1000);
              }else{
                      this.isWaiting=true;
                      this.waitingMessage="Processing your payments please wait.....";
                        var self = this;
                                  setTimeout(function () {
                                    if(self.started==0){
                                       self.started=1
                                       var i = 1000;
                                       self.int2 = setInterval(function () {
                                        self.confirmMpesa(dataReceived);
                                        i += 1000;
                                        if (i >= 25000){
                                          clearInterval(self.int1);
                                          clearInterval(self.int2);
                                          clearInterval(self.int3);
                                          moengage.track_event('Payment Cancelled', {
                                            'BookingId':self.confirmData.data.booking_id,
                                            'PassengerName': self.confirmData.fullname,
                                            'PassengerEmail': self.confirmData.email,
                                            'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                                            'PassengerResidence': self.confirmData.search_from,
                                            'Busoperator': self.confirmData.operator.alias,
                                            'TotalFare':parseInt(self.confirmData.total_fare),
                                            'TotalPassengers':self.confirmData.passengers.length
                                          });
                                          moengage.destroy_session();
                                          window.location.href = '/index.html';
                                        } ;
                                      }, 10000);
                                    }
                                }, 1000);
              }
            },
            error => {
              this.isError=true;
              this.isWaiting=true;
              this.waitingMessage="Processing your payments please wait.....";
              this.api_error=new Error().deserialize(error.error.errors)
              var self = this;
                        setTimeout(function () {
                          if(self.started==0){
                             self.started=1
                             var i = 1000;
                              self.int3 = setInterval(function () {
                              self.confirmMpesa(dataReceived);
                              i += 1000;
                              if (i >= 25000){
                                  clearInterval(self.int1);
                                  clearInterval(self.int2);
                                  clearInterval(self.int3);
                                  moengage.track_event('Payment Cancelled', {
                                    'BookingId':self.confirmData.data.booking_id,
                                    'PassengerName': self.confirmData.fullname,
                                    'PassengerEmail': self.confirmData.email,
                                    'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                                    'PassengerResidence': self.confirmData.search_from,
                                    'Busoperator': self.confirmData.operator.alias,
                                    'TotalFare':parseInt(self.confirmData.total_fare),
                                    'TotalPassengers':self.confirmData.passengers.length
                                  });
                                  moengage.destroy_session();
                                  window.location.href = '/index.html';
                              } ;
                            }, 10000);
                          }
                      }, 1000);
            }
          );
        }

  }
}

import { Component, OnInit } from '@angular/core';
import { User } from '../models/index'
import { UserService } from '../services/user.service'
@Component({
  selector: 'app-mytrips',
  templateUrl: './mytrips.component.html',
  styleUrls: ['./mytrips.component.css']
})
export class MytripsComponent implements OnInit {
  isTripAvailable:boolean = false;loadingStart:boolean=false;
  isLoggedIn:any;
  user:User;
  trips:any
  resMsg:any;
  constructor(
    public users:UserService
  ) { }
  ngOnInit(): void {
    this.isLoggedIn = localStorage.getItem('isLoggedIn');
    if(this.isLoggedIn ){
      this.user=new User().deserialize(JSON.parse(localStorage.getItem('user')))
      let phone = '0' + this.user.phone_number.substring(this.user.phone_number.length - 9);
      this.getUserTrips(phone)
    }
  }
  getUserTrips(phone:string){
    this.users.getTrips(phone).subscribe(
      response => {
          this.loadingStart=false;
          this.resMsg=response;
          this.trips=this.resMsg.rows;
          if(this.trips.length > 0){
            this.isTripAvailable=true;
          }

      },
      error => {


      }
    );
  }

}

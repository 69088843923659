
<app-account-header></app-account-header>
<div class="theme-page-section  theme-page-section-lg">
    <div class="container ">
      <div class="row">
        <div class="col-md-9-5  ">
          <h3 class="theme-payment-page-sections-item-title">Notifications</h3>
          <div class="row">
            <div class="col-md-9 ">
              <div class="row">
                <div class="bus-booking-summ _p-20 ">
                  <div class="col-md-12">
                    <div class="theme-payment-page-sections-item-pay ">
                      <div class="theme-payment-page-signin">
                        <div class="theme-payment-page-signin-body">
                          <h4 class="theme-payment-page-signin-title review-header _mb-10">Promotions</h4>
                        </div>
                      </div>
                    </div>
                    <div class="theme-search-results-sidebar-section-checkbox-list-items tab-content-curved">
                        <div class="checkbox theme-search-results-sidebar-section-checkbox-list-item"  >
                          <div class="_mb-10">
                            <label  class="switch ">
                              <input  type="radio" name="switch-choice" >
                              <span class="slider round"></span>
                            </label>
                            <span class="checkbox-title _ml-10"> Email </span>
                          </div>
                          <div id="collapseMpesa"  class=" _mt-10 _mb-20">
                            <div class="card">
                              <div class="card-body">
                                 Receive coupons, promotions, surveys, product updates,
                                 <br>
                                 and travel inspiration from buupass and our partners.
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmService, DataService, MixpanelService } from '../services/index'
import { Error } from '../models/index'
import { Base64 } from 'js-base64';
import moengage from "@moengage/web-sdk";

@Component({
  selector: 'app-process-retry',
  templateUrl: './process-retry.component.html',
  styleUrls: ['./process-retry.component.css']
})
export class ProcessRetryComponent implements OnInit {
  sub:any;
  confirmData:any;
  res:any;
  isError:boolean;
  errorMessage:any;
  successMessage:any;
  isWaiting:boolean;
  waitingMessage:any;
  api_error:Error;
  started=0;
  int1:any;
  int2:any;
  int3:any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ConfirmService,
    private dataService: DataService,
    private mixpanelService: MixpanelService,

  ) { }
  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      let data= params['id'];
      this.confirmData=JSON.parse(Base64.decode(data));

  

console.log(this.confirmData)

      this.confirmMpesa(this.confirmData);
    });
  }
  confirmMpesa(dataReceived:any){

    this.isWaiting=true;
    this.waitingMessage="Processing your payments please wait.....";
        let pay_id ='';
    
        pay_id = dataReceived.booking_id;

          this.service.confirmV2(pay_id).subscribe(
            response => {
              this.res=response;
              var self = this;
              if(this.res.status =='confirmed'){
                this.isWaiting=false;
                this.successMessage="Payment processed successfully";
                let f=Base64.encode(JSON.stringify(dataReceived))
                clearInterval(self.int1);
                clearInterval(self.int2);
                clearInterval(self.int3);
                this.dataService.changeSuccessMessage(f);
                this.router.navigate(['/retry/successful/:id', 'thank-you'])
              }else if(this.res.status == 'cancelled' ){
                var self = this;
                this.isWaiting=true;
                this.isError=false;
                // this.errorMessage="Sorry your payment request was cancelled. Please follow the instructions sent via sms to confirm your booking";
                this.errorMessage="Sorry your payment request was cancelled.";

                if (this.res.reason == "validation"){
                    setTimeout(function () {
                      clearInterval(self.int1);
                      clearInterval(self.int2);
                      clearInterval(self.int3);
                      moengage.track_event('Payment Cancelled', {
                        'BookingId':self.confirmData.data.booking_id,
                        'PassengerName': self.confirmData.fullname,
                        'PassengerEmail': self.confirmData.email,
                        'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                        'PassengerResidence': self.confirmData.search_from,
                        'Busoperator': self.confirmData.operator.alias,
                        'TotalFare':parseInt(self.confirmData.total_fare),
                        'TotalPassengers':self.confirmData.passengers.length
                      });
                      moengage.destroy_session();
                      window.location.href = '/index.html';
                    }, 10000);
                }
                          setTimeout(function () {
                            if(self.started==0){
                               self.started=1
                               var i = 1000;
                                self.int1 = setInterval(function () {
                                self.confirmMpesa(dataReceived);
                                i += 1000;
                                if (i >= 25000){
                                  clearInterval(self.int1);
                                  clearInterval(self.int2);
                                  clearInterval(self.int3);
                                  moengage.track_event('Payment Cancelled', {
                                    'BookingId':self.confirmData.data.booking_id,
                                    'PassengerName': self.confirmData.fullname,
                                    'PassengerEmail': self.confirmData.email,
                                    'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                                    'PassengerResidence': self.confirmData.search_from,
                                    'Busoperator': self.confirmData.operator.alias,
                                    'TotalFare':parseInt(self.confirmData.total_fare),
                                    'TotalPassengers':self.confirmData.passengers.length
                                  });
                                  moengage.destroy_session();
                                  window.location.href = '/index.html';
                                } ;
                              }, 10000);
                            }
                        }, 1000);
              }else{
                      this.isWaiting=true;
                      this.waitingMessage="Processing your payments please wait.....";
                        var self = this;
                                  setTimeout(function () {
                                    if(self.started==0){
                                       self.started=1
                                       var i = 1000;
                                       self.int2 = setInterval(function () {
                                        self.confirmMpesa(dataReceived);
                                        i += 1000;
                                        if (i >= 25000){
                                          clearInterval(self.int1);
                                          clearInterval(self.int2);
                                          clearInterval(self.int3);
                                          moengage.track_event('Payment Cancelled', {
                                            'BookingId':self.confirmData.data.booking_id,
                                            'PassengerName': self.confirmData.fullname,
                                            'PassengerEmail': self.confirmData.email,
                                            'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                                            'PassengerResidence': self.confirmData.search_from,
                                            'Busoperator': self.confirmData.operator.alias,
                                            'TotalFare':parseInt(self.confirmData.total_fare),
                                            'TotalPassengers':self.confirmData.passengers.length
                                          });
                                          moengage.destroy_session();
                                          window.location.href = '/index.html';
                                        } ;
                                      }, 10000);
                                    }
                                }, 1000);
              }
            },
            error => {
              this.isError=true;
              this.isWaiting=true;
              this.waitingMessage="Processing your payments please wait.....";
              this.api_error=new Error().deserialize(error.error.errors)
              var self = this;
                        setTimeout(function () {
                          if(self.started==0){
                             self.started=1
                             var i = 1000;
                              self.int3 = setInterval(function () {
                              self.confirmMpesa(dataReceived);
                              i += 1000;
                              if (i >= 25000){
                                  clearInterval(self.int1);
                                  clearInterval(self.int2);
                                  clearInterval(self.int3);
                                  moengage.track_event('Payment Cancelled', {
                                    'BookingId':self.confirmData.data.booking_id,
                                    'PassengerName': self.confirmData.fullname,
                                    'PassengerEmail': self.confirmData.email,
                                    'PassengerPhone': '254' + self.confirmData.phoneno.slice(-9),
                                    'PassengerResidence': self.confirmData.search_from,
                                    'Busoperator': self.confirmData.operator.alias,
                                    'TotalFare':parseInt(self.confirmData.total_fare),
                                    'TotalPassengers':self.confirmData.passengers.length
                                  });
                                  moengage.destroy_session();
                                  window.location.href = '/index.html';
                              } ;
                            }, 10000);
                          }
                      }, 1000);
            }
          );
        

  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FromStatusService {
  private statusSource = new BehaviorSubject<boolean>(false);
  private statusDateSource = new BehaviorSubject<boolean>(false);
  private fromValue = new BehaviorSubject<string>('');
  private fromValueMobile = new BehaviorSubject<string>('');

  statusValue = this.statusSource.asObservable();
  statusDateValue = this.statusDateSource.asObservable();
  valueChange = this.fromValue.asObservable();
  valueMobileChange = this.fromValueMobile.asObservable();

  constructor() { }

  changeValue(value: boolean) {
    this.statusSource.next(value)
  }
    changeDateValue(value: boolean) {
    this.statusDateSource.next(value)
  }

  changeSelectedValue(value: string) {
    this.fromValue.next(value)
  }

  changeSelectedMobileValue(value: string) {
    this.fromValueMobile.next(value)
  }
}

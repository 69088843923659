
<app-account-header></app-account-header>
<div class="theme-page-section  theme-page-section-lg">
    <div class="container ">
      <div class="row">
        <div class="col-md-9-5 ">
            <div class="theme-account-history tab-content-curved">
              <h3 class="theme-payment-page-sections-item-title">Booking History</h3>
              <div *ngIf="!isTripAvailable" class=" row _p-50">
                  <div class="col-md-12">
                          <div class="text">
                              <div class="content text-center">
                                  <h4 class="heading">You haven't made any bookings through buupass</h4>
                                  <p>You can retrieve any bookings that aren't already listed here..</p>
                              </div>
                      </div>
                  </div>
                </div>
              <table *ngIf="isTripAvailable" class="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Route</th>
                    <th>Operator</th>
                    <th>Date</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let trip of trips">
                    <td class="theme-account-history-type">
                      <i class="fa fa-bus theme-account-history-type-icon"></i>
                    </td>
                    <td>
                      <p class="theme-account-history-type-title">{{trip.route}}</p>
                    </td>
                    <td>
                      <a href="#">{{trip.operator}}</a>
                    </td>
                    <td class="theme-account-history-tr-date">
                      <p class="theme-account-history-date">{{trip.departure_date}}</p>
                    </td>
                    <td>
                      <p class="theme-account-history-item-price">Ksh {{trip.total_amount}}</p>
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>
          </div>

      </div>
    </div>
</div>

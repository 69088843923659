import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TownsService {

  townsDataSource: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  currentValue = this.townsDataSource.asObservable();

  constructor() { }

  changeValue(value: any) {
      this.townsDataSource.next(value)
    }
}

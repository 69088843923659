import { Deserializable } from './deserializable.model';

export class Results implements Deserializable {
    hide_from_api:boolean =false;
    hide_from_ui:boolean;
    trip_id:number;
    schedule: any;
    route: any;
    pickup:any;
    dropoff:any;
    type:number;
    fleet: any;
    boarding_points:any;
    seat_price: any;
    deserialize(input: any): this{
        Object.assign(this, input);
        return this;
    }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-retry-successful',
  templateUrl: './retry-successful.component.html',
  styleUrls: ['./retry-successful.component.css']
})
export class RetrySuccessfulComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

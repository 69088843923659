import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleCase'
})
export class TitleCasePipe implements PipeTransform {

  transform(word: any): any {
    if (!word) return word;
      return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

}

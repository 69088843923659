import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class PassDepatureValueService {

  private depatureSource = new BehaviorSubject<string>('');
  depatureValue = this.depatureSource.asObservable();
  constructor() { }

  changeValue(value: string) {
    this.depatureSource.next(value)
  }
}

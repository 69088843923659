import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-depature-date',
  templateUrl: './depature-date.component.html',
  styleUrls: ['./depature-date.component.css']
})
export class DepatureDateComponent implements OnInit {
  // bsInlineValue = new Date();
  displayMonths = 12;
  today=new Date();
  bsValue = new Date();
  bsInlineRangeValue: Date[];
  ngOnInit(): void {
  }
  constructor(
    private router: Router,
    private dataService: DataService
  ) {

  }

  onValueChange(value: Date): void {
    const date=formatDate(value,"yyyy-MM-dd","en-US");
    this.dataService.changeTravelMessage(date);
    this.router.navigateByUrl('/home');
  }




}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameSplit'
})
export class NameSplitPipe implements PipeTransform {

  transform(fullName: string, arg: 'first' | 'last'): string {
    if (!fullName) {
      return '';
    }

    let trimmedName = fullName.trim();
    let nameParts = trimmedName.split(' ');

    if (arg === 'first') {
      return nameParts[0] || '';
    } else if (arg === 'last' && nameParts.length > 1) {
      return nameParts[nameParts.length - 1] || '';
    }

    return '';
  }

}

import { Deserializable } from './deserializable.model';


export class Query implements Deserializable {
    bus_id:string;
    date_of_travel:string;
    route_schedule_id:string;
    origin_city_id:string;
    destination_city_id:string;
    from:string;
    to:string;

    deserialize(input: any): this{
        Object.assign(this, input);
        return this;
    }

}
 
<div class="hidden-sm hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg">
  <app-headerhome ></app-headerhome>
</div>
<div class="container _mt-50 bg-buupass">
</div>
<div class="theme-page-section _p-20 buupass-pink-light-bg ">
      <div class="container">
        <div class="row">
          <div class="theme-account-avatar-none text-white">
            <img class="theme-account-avatar-img" src="assets/img/company1/user.png" alt="Image Alternative text" title="Image Title">
            <p class="theme-account-avatar-name">Welcome,
              <br>{{user.first_name | titleCase}} {{user.last_name | titleCase}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2 _ml-50   theme-page-section-lg">
          <div class="theme-account-sidebar  tab-content-curved">
            <nav class="theme-account-nav">
              <ul class="theme-account-nav-list">
                <li [ngClass]="{'active': account}" >
                  <a href="/account">
                    <i class="fa fa-cog"></i>Passenger Details
                  </a>
                </li>
                <li [ngClass]="{'active': notifications}">
                  <a href="/my-notifications">
                    <i class="fa fa-bell"></i>Notifications
                  </a>
                </li>
                <!-- <li>
                  <a href="/my-trips">
                    <i class="fa fa-user-circle-o"></i>Travelers
                  </a>
                </li> -->
                <li  [ngClass]="{'active': trips}">
                  <a href="/my-trips">
                    <i class="fa fa-history"></i>History
                  </a>
                </li>
                <li>
                  <a (click)="logoutUser()" href="#!">
                    <i class="fa fa-lock" aria-hidden="true"></i>
                      Logout
                  </a>
                </li>

              </ul>
            </nav>
          </div>
    </div>

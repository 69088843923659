import { Component, OnInit, Input, Output, EventEmitter, HostListener, forwardRef, ElementRef } from '@angular/core';
import { AbstractControl,ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { formatDate } from '@angular/common';
import { transition, trigger, style, animate } from '@angular/animations';
const noop = () => {
};

import { PassDepatureValueService } from 'src/app/services/pass-depature-value.service';
import { PassDestinationValueService } from 'src/app/services/pass-destination-value.service';
import { FromStatusService } from 'src/app/services/from-status.service';
import { DropdownStatusService } from 'src/app/services/dropdown-status.service';


const monthNames = {
  'January':'01',
  'February':'02',
  'March':'03',
  'April':'04',
  'May':'05',
  'June':'06',
  'July':'07',
  'August' :'08',
  'September':'09',
  'October':'10',
  'November':'11',
  'December':'12' 
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateSelectComponent),
  multi: true
};

@Component({
  selector: 'app-date-select',
  templateUrl: './date-select.component.html',
  styleUrls: ['./date-select.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class DateSelectComponent implements OnInit, ControlValueAccessor {

  @Input() className: string;
  @Input() placeholder: string;

  @Input() isReadOnly = false;
  @Output() optSelect = new EventEmitter();
  isOpen = false;
  selectedOption;
  location:string;
  tripType
  control: AbstractControl | null;
  bsInlineValue =new Date(formatDate(new Date(),"yyyy-MM-dd","en-KE"));
  today=new Date();
  bsValue = new Date();
  bsInlineRangeValue: Date[];
  maxDate = new Date();
  traveldate;
  returndata;
  depatureTown: string;
  destinationTown: string;
  journeyType='One Way';
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  isSelectedValue: boolean;
  key: string;
  isFocused: boolean;
  dataOptions:any;
  timer;
  /**
   *Creates an instance of DateSelectComponent.
   * @memberof DateSelectComponent
   */

   constructor(
    private depature: PassDepatureValueService,
    private destination: PassDestinationValueService,
    private fromService:FromStatusService,
    private service:DropdownStatusService,
    private elRef: ElementRef
   ){
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.traveldate=formatDate(new Date(),"yyyy-MM-dd","en");
    this.returndata=this.journeyType;

    depature.depatureValue.subscribe(message => {
      this.depatureTown=message;
    });
    destination.destinationValue.subscribe(message => {
      this.destinationTown=message;
    });

    this.fromService.statusValue.subscribe(message => {
      if(message) this.isOpen = false
    });

    this.service.statusValue.subscribe(message => {
      if(message) this.isOpen = false
    });

    this.journeyType='One Way';
   }

   onValueChange(value: Date): void {
    this.handleClickDateRangePicker();
    var hElement: HTMLElement = this.elRef.nativeElement;
    let elem =hElement.getElementsByClassName("focusDatePick");
    elem[0].scrollIntoView({ behavior: "smooth", block: "center" });
    if (value[1] == null){

      }else{
        this.returndata=formatDate(value[1],"yyyy-MM-dd","en-US");
        this.traveldate=formatDate(value[0],"yyyy-MM-dd","en-US");
        this.journeyType='Return';
        this.onChangeCallback(this.traveldate+':'+this.returndata);
      }
    //this.journeyType='Return';
  }

  handleClickDateRangePicker() {
    setTimeout(() => {
    let classname = document.getElementsByClassName("bs-datepicker-body");
   
      for (let i = 0; i < classname.length; i++) {
        let idName = "bs-date-range-picker-" + i;
        classname[i].setAttribute("id", idName);
        let start_dt;
        var self=this;

        document.getElementById(idName).addEventListener('click', function (event) {
          let dd = event['srcElement']['innerText'];
          var f=event.composedPath && event.composedPath()[6] || event['path']
          let junk_str =  f.innerText;
          let trimmed_str = junk_str.replace(/\s/g, '');
          trimmed_str = trimmed_str.replace('↵','');
          let firstDigit = trimmed_str.match(/\d/);
          let indexed = trimmed_str.indexOf(firstDigit);
          let indexed_of_endYear = trimmed_str.indexOf('›');
          let sliced_month = trimmed_str.slice(1, indexed);
          let sliced_year = trimmed_str.slice(indexed, indexed_of_endYear);
          let result=escape(sliced_month).replace("%u200B", '');
          let newmonth=result.replace("%u200B", '');
          let newyear=escape(sliced_year).replace("%u200B", '');
          start_dt =newyear+'-'+monthNames[newmonth]+'-'+dd;
          self.traveldate=formatDate(start_dt,"yyyy-MM-dd","en-US");;
          self.returndata='One Way';
          self.journeyType='One Way';
          self.onChangeCallback(self.traveldate+':'+self.returndata);
        })
        
      }
    }, 1000)}

  ngOnInit() {
    this.handleClickDateRangePicker();

  }



  assignValue(){
    
   
  }

  callType(value){
  
    this.returndata=value;
  }

 
  @HostListener('focus')
  focusHandler() {
   
    this.isFocused = true;
  }

  setValue(location:string) { this.location = location; }

  @HostListener('focusout')
  focusOutHandler() {
    this.isFocused = false;
  }



  @HostListener('document:keydown', ['$event'])
  keyPressHandle(event: KeyboardEvent) {
    
    if (this.isFocused) {
      this.key = event.code;

      switch (this.key) {
        case 'Space':
          this.isOpen = true;
         
          break;
        case 'ArrowDown':
          
          break;
        case 'ArrowUp':
          
          break;
        case 'Enter':
         
          break;
      }
    }

  }

  /**
  * option selection
  * @param {string} selectedOption - text
  * @param {number} idx - current index of item
  * @param {any} event - object
  */



  /**
  * toggle the dropdown
  * @param {any} event object
  */
  toggle(e: any) {
    this. handleClickDateRangePicker();
    e.stopPropagation();
    this.setValue('');
    // close all previously opened dropdowns, before open
    const allElems = document.querySelectorAll('.dropdown-wrapper');
    for (let i = 0; i < allElems.length; i++) {
      allElems[i].classList.remove('is-open');
    }
    this.isOpen = true;
    this.service.changeDateValue(true);
    this.fromService.changeDateValue(true);
    this.bsInlineRangeValue = [new Date()];
    this.returndata='One Way';
    this.journeyType='One Way';

  }



  doneClick(){
    this.isOpen = false;
  }

  /**
   * Method implemented from ControlValueAccessor and set default selected value
   * @param {*} obj
   * @memberof DateSelectComponent
   */
  writeValue(obj: any): void {

    if (obj && obj !== '') {
      this.isSelectedValue = true;
     
    } else {
      this.isSelectedValue = false;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {

  }

}
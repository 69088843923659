import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class PassDestinationValueService {


  private destinationSource = new BehaviorSubject<string>('');
  destinationValue = this.destinationSource.asObservable();
  constructor() { }

  changeValue(value: string) {
    this.destinationSource.next(value)
  }

}

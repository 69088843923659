
<app-account-header></app-account-header>
<div class="theme-page-section  theme-page-section-lg">
    <div class="container ">
      <div class="row">
        <div class="col-md-9-5  ">
          <h3 class="theme-payment-page-sections-item-title">Passenger Details</h3>
          <div class="row">
            <div class="col-md-9 ">
              <div class="theme-payment-page-sections-item bg_white _p-20">
                <div class="theme-payment-page-signin">
                  <i class="theme-payment-page-signin-icon fa fa-user-circle-o"></i>
                  <div class="theme-payment-page-signin-body">
                    <ul class="theme-sidebar-section-features-list">
                    <li>
                      <h5 class="theme-sidebar-section-features-list-title">Manage your bookings!</h5>
                      <p class="theme-sidebar-section-features-list-body">These details must match your passport or ID card.</p>
                    </li>
                  </ul>
                  </div>
                </div>
              </div>
              <div class="theme-payment-page-sections-item tab-content-curved _p-30">
                <div class="theme-payment-page-form">
                  <div class="row row-col-gap" data-gutter="20">
                    <div class="col-md-6 ">
                      <div class="theme-payment-page-form-item form-group">
                        <input class="form-control" type="text" value="{{user.first_name}}" placeholder="First Name">
                      </div>
                    </div>
                    <div class="col-md-6 ">
                      <div class="theme-payment-page-form-item form-group">
                        <input class="form-control" type="text" value="{{user.last_name}}" placeholder="Last Name">
                      </div>
                    </div>
                    <div class="col-md-6 ">
                      <div class="theme-payment-page-form-item form-group">
                        <input class="form-control" type="text" value="{{user.email}}" placeholder="Email Address">
                      </div>
                    </div>
                    <div class="col-md-6 ">
                      <div class="theme-payment-page-form-item form-group">
                        <input class="form-control" type="text" value="{{user.phone_number}}" placeholder="Phone Number">
                      </div>
                    </div>
                    <div class="col-md-12 _p-20">
                      <a class="theme-search-area-submit theme-payment-page-signin-btn btn-primary" href="#">Update</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
</div>

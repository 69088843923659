<div class="row">
          <div class="col-lg-12 bg-grey text-white stuck">
              <div class="input-group _p-10" >
                  <input #fromInputFocus class="form-control-search" id="white-placeholder" class="theme-search-area-section-input buupass-pink-light-bg _pl-15"
                   type="text"
                   (ngModelChange)="updateSearchModel($event)"
                   [(ngModel)]="searchModel"
                   type="text"
                   placeholder="Search"
                   autofocus 
                   >
                  <span class="input-group-btn">
                    <button (click)="cancel()" class="theme-search-area-submit theme-search-area-submit-curved buupass-orange-bg" type="button">
                        CANCEL
                  </button>
              </span>
              </div>
          </div>
</div>
<div *ngIf="cities.length == 0" class="_pt-100">
    <div  class="row buupass-container _mt-5 _mb-5">
    <div class="col-lg-2">
      <span class="trip-font">Loading .....</span>
    </div>
    <hr>
    </div>
</div>
<div class="mobile-list _pt-70" *ngIf="cities">
    <div  *ngFor="let city of cities | townFilter: searchModel" (click)="onSelect(city)" class="row buupass-container _mt-5 _mb-5" >
    <div class="col-lg-2 mobile-list-item" >
        <i class="fa fa-map-marker-alt _mr-10" aria-hidden="true"></i><span class="trip-font">{{city.name}}</span>
    </div>
    <hr>
    </div>
</div>

import { Deserializable } from './deserializable.model';

export class Destinations implements Deserializable {
    id: number;
    name:string
    hide_from_api:boolean
    deserialize(input: any): this{
        Object.assign(this, input);
        return this;
    }

}

import { Injectable } from '@angular/core';

declare let fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class PixelService {

  constructor() { }

  // Custom events

  trackPageView() {
    fbq('track', 'PageView');
  }

  trackEvent(eventName: string, parameters?: any) {
    fbq('track', eventName, parameters);
  }

  trackCustom(eventName: string, parameters?: any) {
    fbq('trackCustom', eventName, parameters);
  }



  

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  private messageSource = new BehaviorSubject('');
  public travelValueMobile = new BehaviorSubject<string>('');
  public returnValueMobile = new BehaviorSubject<string>('');
  public returnSearchValue = new BehaviorSubject<string>('');
  public returnPaymentValue = new BehaviorSubject<string>('');
  public reviewPaymentValue = new BehaviorSubject<string>('');
  public successValue = new BehaviorSubject<string>('');
  currentMessage = this.messageSource.asObservable();
  traveldateMobileChange = this.travelValueMobile.asObservable();
  returndateMobileChange = this.returnValueMobile.asObservable();
  returnSearchValueChange = this.returnSearchValue.asObservable();
  returnPaymentValueChange = this.returnPaymentValue.asObservable();
  reviewPaymentValueChange = this.reviewPaymentValue.asObservable();
  successValueChange = this.successValue.asObservable();
  changeMessage(message: string) {
    this.messageSource.next(message)
  }
  changeTravelMessage(message: string) {
    this.travelValueMobile.next(message)
  }
  changeReturnMessage(message: string) {
    this.returnValueMobile.next(message)
  }
  changeSearchMessage(message: string) {
    localStorage.setItem('search-buupass', message);
    this.returnSearchValue.next(message)
  }
  changePaymentMessage(message: string) {
    this.returnPaymentValue.next(message)
  }

  changeReviewPaymentMessage(message: string) {
    this.reviewPaymentValue.next(message)
  }
  changeSuccessMessage(message: string) {
    this.successValue.next(message)
  }

  constructor() { }
}


<div class="row">
    <div class="col-md-12">
        <bs-datepicker-inline
        [minDate]="today"
        (bsValueChange)="onValueChange($event)"
         [bsConfig]="{ returnFocusToInput: true }"
        ></bs-datepicker-inline>
    </div>
</div>

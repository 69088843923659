<div class="cart-timer-box">
    <div class="cart-timer-container">
        <svg class="cart-timer-visual" aria-hidden="true">
            <circle cx="12" cy="12" r="10" class="cart-timer-visual__circle"></circle>
            <circle cx="12" cy="12" r="10" [attr.stroke-dashoffset]="circleOffset" class="cart-timer-visual__circle cart-timer-visual__circle--progress"></circle>
        </svg>
        <div class="flix-space-2-left">
            <b class="flix-sr-only" role="timer" aria-live="off">Your session will expire in {{ timerDisplay }} min.</b>
            <b class="cart-timer-text" aria-hidden="true">{{ timerDisplay }}</b>
        </div>
    </div>
</div>

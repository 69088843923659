<div class="hidden-sm hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg">
  <app-headerhome ></app-headerhome>
</div>
    <div id="container ">

        <div class="bus-booking-page ">

                <div class="page-content row">

                    <div class="col-md-12">
                      <div class="text-center">
                      <h2 class="_mt-120 mobile-view">Redirecting You to booking.com to complete your booking</h2>
                      <p><img class="_mt-20" width="300" src="assets/img/images/booking-com.png"/></p>

                      <svg class="_mt-20"  xmlns:svg="http://www.w3.org/2000/svg"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="152px" height="10px" viewBox="0 0 457 60" xml:space="preserve">
                          <g>
                              <circle fill="#007929" cx="-31" cy="30" r="30"/>
                              <circle fill="#329353" cx="-97" cy="30" r="24"/>
                              <circle fill="#64ae7d" cx="-163" cy="30" r="19"/>
                              <circle fill="#c8e2d1" cx="-229.5" cy="30.5" r="13.5"/>
                              <circle fill="#e1efe6" cx="-295" cy="31" r="11"/>
                              <animateTransform attributeName="transform" type="translate" values="61 0;127 0;193 0;259 0;325 0;391 0;457 0;523 0;589 0;655 0;721 0;787 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;" calcMode="discrete" dur="1280ms" repeatCount="indefinite"/>
                          </g>
                          <g>
                              <circle fill="#007929" cx="488" cy="30" r="30"/>
                              <circle fill="#329353" cx="554" cy="30" r="24"/>
                              <circle fill="#64ae7d" cx="620" cy="30" r="19"/>
                              <circle fill="#c8e2d1" cx="686.5" cy="30.5" r="13.5"/>
                              <circle fill="#e1efe6" cx="753" cy="31" r="11"/>
                              <animateTransform attributeName="transform" type="translate" values="0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;-61 0;-127 0;-193 0;-259 0;-325 0;-391 0;-457 0;-523 0;-589 0;-655 0;-721 0;-787 0;" calcMode="discrete" dur="1280ms" repeatCount="indefinite"/>
                          </g>
                      </svg>

                      <h4 class="_mt-20">In partnership with</h4>
                      <p><img class="_mt-20" width="300" src="assets/img/company1/1.png"/></p>

                      </div>

                    </div>
                </div>
        </div>
    </div>
<app-footer></app-footer>

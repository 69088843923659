import { Component, OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.css']
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  timerDisplay: string;
  circleOffset: number;
  readonly circleLength = 62.83185307179586; // 2 * π * r

  ngOnInit() {
    const countdown = 8 * 60; // 8 minutes in seconds
    this.subscription = interval(1000).pipe(
      take(countdown),
      map((value) => countdown - value - 1)
    ).subscribe((value) => {
      const minutes = Math.floor(value / 60);
      const seconds = value % 60;
      this.timerDisplay = `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      this.circleOffset = this.circleLength - (value / countdown) * this.circleLength;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
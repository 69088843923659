import { Deserializable } from './deserializable.model';

export class Error implements Deserializable {

    public error: string;


    deserialize(input: any): this{
        Object.assign(this, input);
        return this;
    }

    getErrorMessage() {
      return this.error;
    }
    
    getTheMessage(err: any) {
      return err[0];
    }
  

}

<div class="bus-listing-page ">
        <div class="page-content ">
                <div class="bus-listing-rows">
                    <div class="no-results">
                        <div class="sr-row row ride--booked js-ride--booked">
                            <div class="col-md-12">
                                    <div class="img-holder text-center"><img src="assets/img/greenline/bus.png" width="100" height="150"></div>
                                    <div class="text">

                                        <div class="content text-center">
                                            <h4 class="heading">No Buses found</h4>
                                            <p>Sorry! We couldn't find any buses matching your search. Please change
                                            the date or search for an alternative route.</p>
                                        </div>
                                </div>
                            </div>
                          </div>
                    </div>
                </div>
        </div>
    </div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry } from 'rxjs/operators'
import  { environment } from '../../environments/environment'

const httpOptions = {
  headers: new HttpHeaders({
   'Authorization':  'Api-Key '+ environment.api_key
  })
};

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  constructor(
    private http:HttpClient
  ) { }

  onSearch(search : any) {
    if(search.returning){
      return this.http.get(`${environment.searchBuses}` + '?leaving_from='+search.pickup+'&going_to='+search.dropoff+'&departing_on='+search.traveldate+'&return_date='+search.returndate,httpOptions)
      .pipe(
        retry(3),
      );
    }
    return this.http.get(`${environment.searchBuses}` + '?leaving_from='+search.pickup+'&going_to='+search.dropoff+'&departing_on='+search.traveldate,httpOptions)
    .pipe(
      retry(3),
    );
  }
  getSeats(query:any){
    return this.http.get(`${environment.getSeats}`  +'?bus_id='+query.bus_id + '&leaving_from='+query.leaving_from+'&going_to='+query.going_to+'&departing_on='+ '&rsc_id='+query.rsc_id + '&start_point='+query.start_point + '&end_point='+query.end_point +'&alias='+query.alias+'&date='+query.date + '&fleet_registration_id='+query.fleet_registration_id + '&no_of_seats='+query.no_of_seats + '&fare='+query.fare + '&id='+query.id,httpOptions)
    .pipe(
      retry(3),
    );
  }


}
